import axios from "axios";
import { useCallback, useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import dateformat from "dateformat";
import Loader from "../elements/Loader";
import { API_KEY, API_URL } from "../../config/constants";

const ChatMessage = ({ message }) => {
    const isImage = message.chat_type === "image";
    const hasContent = isImage ? message.file_url : message.chats;

    if (!hasContent) {
        return null;
    }

    const date = new Date(message.created_date);
    const formattedDate = dateformat(date, "mmm d, yyyy 'at' h:MM TT");
    const userName = message.user_name || "User";

    return (
        <div style={{ marginBottom: "10px" }}>
            <p
                style={{
                    fontWeight: "bold",
                    marginBottom: "5px",
                    fontSize: "14px",
                }}
            >
                {userName}
            </p>
            {isImage && message.file_url && <img src={message.file_url} alt="Chat image" style={{ maxWidth: "200px", borderRadius: "8px" }} />}
            {!isImage && message.chats && (
                <p
                    style={{
                        backgroundColor: "#f0f0f0",
                        padding: "8px",
                        borderRadius: "8px",
                        display: "inline-block",
                    }}
                >
                    {message.chats}
                </p>
            )}
            <p style={{ fontSize: "12px", color: "#666", marginTop: "5px" }}>{formattedDate}</p>
        </div>
    );
};

function ViberAiChats({ loan_id }) {
    const [loading, setLoading] = useState(false);
    const [viberChats, setViberChats] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);

    const getViberChats = useCallback(
        async (pageNum) => {
            if (!hasMore) return;
            setLoading(true);
            try {
                const params = { loan_id, page: pageNum, limit: 10 };
                const headers = { "Content-Type": "application/json", apiKey: API_KEY};
                const response = await axios.post(`${API_URL}ai-chat/chats-by-loan-id`, params, { headers });
                if (response?.data?.status === 200) {
                    const { chats: newChats, hasMore: moreChats } = response.data.data;
                    setViberChats((prev) => [...prev, ...newChats]);
                    setHasMore(moreChats);
                } else {
                    toast.error(response?.data?.message || "Error");
                }
            } catch (error) {
                toast.error(error.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        },
        [loan_id]
    );

    useEffect(() => {
        getViberChats(page);
    }, [getViberChats, page]);

    const handleScroll = () => {
        const container = containerRef.current;
        if (!container) return;

        const isNearTop = container.scrollHeight + container.scrollTop <= container.clientHeight + 100;

        if (isNearTop && !loading && hasMore) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    return (
        <div style={{ height: "600px", display: "flex", flexDirection: "column" }}>
            {loading && <Loader />}
            <div
                ref={containerRef}
                onScroll={handleScroll}
                style={{
                    maxWidth: "600px",
                    margin: "0 auto",
                    padding: "20px",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column-reverse",
                    flex: 1,
                }}
            >
                {viberChats.length > 0 ? viberChats.map((chat) => <ChatMessage key={chat.id} message={chat} />) : <p>No chats available.</p>}
            </div>
        </div>
    );
}

export default ViberAiChats;
